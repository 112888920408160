<template>
  <section>
    <h1 class="text-h5 mt-8">
      Asignar versión de cifrado a usar en institución
    </h1>

    <v-row class="mt-8">
      <v-col cols="12" sm="5">
        <v-autocomplete
          v-model="cifradoSelected"
          :items="versiones"
          item-text="version"
          item-value="id"
          label="Versión de cifrado"
          outlined
          dense
          return-object
        ></v-autocomplete>

        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in cifradoSelected?.elementos"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.posicion }} -
                  {{ item.nombre_elemento }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-btn
          class="mt-4"
          color="primary"
          @click="asignarCifradoInstitucion()"
          :disabled="!cifradoSelected"
        >
          Asignar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
  import { mapState, mapActions } from "vuex";

  export default {
    name: "CifradoInstitucionView",
    data: () => ({
      versiones: [],
      cifradoSelected: null,
    }),
    computed: {
      ...mapState(["selectedUnidad"]),
    },
    methods: {
      ...mapActions(["getUnidades"]),
      async getVersionCifrados() {

        const response = await this.services.Cifrados.getVersionesCifrados();
        this.versiones = response.data;

        const cifrado = this.versiones.find(
          (item) =>
            item.version === this.selectedUnidad.instituciones.version_cifrado
        );

        this.cifradoSelected = cifrado;


      },
      async asignarCifradoInstitucion() {

        const response =
          await this.services.Cifrados.putVersionInstitucion(
            this.selectedUnidad.instituciones.id,
            { cifrado: this.cifradoSelected }
          );

        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Se asignó el cifrado correctamente",
            type: "success",
          });
          
          location.reload();
        }

      },
    },
    async created() {
      await this.getVersionCifrados();
    },
  };
</script>
